.CheckinCard {
  max-width: var(--normal-width);
  margin-left: auto;
  margin-right: auto;

  line-height: var(--line-height);

  display: grid;
  grid-template-columns: 2rem 7rem 1fr 2fr;
  grid-template-rows: auto;
  grid-template-areas:
    ' serial  callsign  operator  location '
    ' .       status    status    remarks      ';

  padding-top: var(--spacing-50);
  padding-bottom: var(--spacing-50);
}

@media (max-width: 800px) {
  .CheckinCard {
    grid-template-columns: 2rem 7rem 0rem 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      ' serial  callsign  operator  operator  '
      ' .       status    location  location  '
      ' .       .         remarks   remarks   ';
    line-height: 20px;
  }

  .Checkin.open .CheckinCard {
    grid-template-columns: 2rem 7rem 4rem 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      ' serial  callsign  operator  operator  '
      ' .       .         location  location  '
      ' .       status    status    status    '
      ' .       .         remarks   remarks   ';
    line-height: 20px;
  }
}

.Checkin.odd {
  background-color: var(--row-odd-background-color);
}

.Checkin.even {
  background-color: var(--row-even-background-color);
}

.Checkin .SerialNo-field {
  grid-area: serial;
}

.Checkin .Status-section {
  grid-area: status;
  font-size: 90%;

  text-align: left;
  padding-left: var(--spacing-200);
}

.Checkin .Status-section span {
  display: inline-block;
  white-space: nowrap;
  margin-left: var(--spacing-50);
  margin-right: var(--spacing-50);
}

.Checkin .Callsign-field {
  grid-area: callsign;
  text-align: left;
  padding-left: var(--spacing-200);
  margin-bottom: var(--spacing-50);
}

.Checkin .Operator-section {
  grid-area: operator;
}

.Checkin .Location-section {
  grid-area: location;
}

.Checkin .Remarks-section {
  grid-area: remarks;
  font-size: 90%;
}

.Checkin.ci_operator {
  background-color: var(--checkin-self-background-color);
}

.Checkin.ci_operator .you {
  font-weight: bold;
  color: var(--checkin-self-text-color);
  text-transform: uppercase;
}

.Checkin.ci_wanted .wanted {
  color: var(--checkin-wanted-text-color);
  font-weight: bold;
}

.Checkin.ci_wanted.even {
  background-color: var(--checkin-wanted-even-background-color);
}
.Checkin.ci_wanted {
  background-color: var(--checkin-wanted-even-background-color);
}

.Checkin.ci_unavailable .SerialNo-field,
.Checkin.ci_unavailable .Callsign-field,
.Checkin.ci_unavailable .Operator-section,
.Checkin.ci_unavailable .Location-section,
.Checkin.ci_unavailable .Remarks-section {
  opacity: 0.25;
}

.Checkin.ci_netcontrol .Operator-section {
  color: var(--checkin-netcontrol-text-color);
  font-weight: bold;
}

.Checkin.ci_netcontrol .tagNetControl {
  background-color: var(--checkin-netcontrol-tag-color);
  color: var(--inverse-text-text-color);
  font-weight: bold;
}

.Checkin.ci_relay .Operator-section {
  color: var(--checkin-relay-text-color);
  font-weight: bold;
}

.Checkin.ci_relay .tagRelay {
  background-color: var(--checkin-relay-tag-color);
  color: var(--inverse-text-color);
  font-weight: bold;
}

.Checkin.ci_operating {
  border-top: var(--highlight-border-size) solid var(--checkin-operating-text-color);
  border-bottom: var(--highlight-border-size) solid var(--checkin-operating-text-color);
  background-color: var(--checkin-operating-background-color);
}
.Checkin.ci_operating .operating {
  font-weight: bold;
  color: var(--checkin-operating-text-color);
  text-transform: uppercase;
}

.Checkin .SerialNo-field {
  text-align: right;
}

.Checkin .Callsign-field .callsign {
  font-weight: bold;
}

.Checkin .Name-field {
  font-weight: bold;
}

.Checkin .Operator-section > span {
  margin-right: var(--spacing-100);
}

.Checkin .MemberID-field {
  margin-left: var(--spacing-200);
}

.Checkin .Country-field {
  text-transform: capitalize;
}

.Checkin .StateHunting-field {
  display: inline-block;
  text-align: center;
  min-width: 2rem;
  padding-left: var(--spacing-100);
  padding-right: var(--spacing-100);

  border-radius: var(--tag-border-radius);
}

.Checkin .City-field {
  margin-left: var(--spacing-100);
}

.Checkin .County-field {
  margin-left: var(--spacing-200);
}

.Checkin .State-field {
  margin-left: var(--spacing-100);
}

.Checkin .Country-field {
  margin-left: var(--spacing-200);
}

.Checkin .tag {
  font-size: 80%;
  font-weight: normal;
  display: inline-block;
  background-color: var(--tag-background-color);
  color: var(--tag-text-color);
  padding-left: var(--spacing-100);
  padding-right: var(--spacing-100);
  border-radius: var(--tag-border-radius);

  margin-right: var(--spacing-50);
}

.MemberID-field.tagMemberID {
  font-weight: bold;
  color: var(--text-color);
}

.Checkin.ci_confirmed_callsign .Callsign-field span {
  text-decoration: line-through !important;
  color: var(--checkin-confirmed-text-color) !important;
}

.Checkin.ci_worked_callsign.even,
.Checkin.ci_confirmed_callsign.even {
  background-color: var(--checkin-worked-even-background-color);
}

.Checkin.ci_worked_callsign.odd,
.Checkin.ci_confirmed_callsign.odd {
  background-color: var(--checkin-worked-odd-background-color);
}

.Checkin.ci_worked_callsign .worked {
  color: var(--checkin-worked-text-color);
}

.Checkin.ci_worked_callsign .Callsign-field span {
  text-decoration: line-through !important;
  color: var(--checkin-worked-text-color) !important;
}

.Checkin.ci_confirmed_state .StateHunting-field {
  text-decoration: line-through !important;
  color: var(--checkin-confirmed-text-color) !important;
}

.Checkin.ci_worked_state .StateHunting-field {
  text-decoration: line-through !important;
  color: var(--checkin-worked-text-color) !important;
  background-color: var(--checkin-worked-tag-color) !important;
}

.Checkin.ci_new_state .StateHunting-field {
  background-color: var(--checkin-hunting-tag-color);
}

.Checkin.ci_worked_state_mixed .StateHunting-field {
  background-color: var(--checkin-worked-mixed-tag-color);
}

.Checkin a,
.Checkin a:link,
.Checkin a:visited {
  color: inherit;
  text-decoration: none;
}

.Checkin a:active,
.Checkin a:hover {
  text-decoration: underline;
  color: var(--link-color);
}

.CheckinsSection.view-wanted .Checkin {
  max-height: 2em;
  overflow-y: hidden;
  opacity: 0.6;
}

.CheckinsSection.view-wanted .CheckinCard {
  font-size: 70%;
}

.CheckinsSection.view-wanted .Checkin.open {
  max-height: inherit;
}

.CheckinsSection.view-wanted .Checkin.ci_wanted {
  max-height: inherit;
  opacity: 1;
}

.CheckinsSection.view-wanted .ci_wanted .CheckinCard {
  font-size: 100%;
}

.CheckinsSection.view-wanted .CheckinCard {
  font-size: 0.7rem;
}

/* .CheckinsSection.view-wanted .Checkin.ci_wanted.ci_unavailable {
  max-height: 2em;
  overflow-y: hidden;
  font-size: 0.7rem;
  opacity: 0.8;
}

.CheckinsSection.view-wanted .ci_wanted .CheckinCard {
  font-size: 100%;
} */

.CheckinsSection.view-wanted .Checkin.ci_operator,
.CheckinsSection.view-wanted .Checkin.ci_operating {
  opacity: 1;
}
